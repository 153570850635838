<template>
  <div>
    <router-view v-show='this.$route.matched.length==3'></router-view>
    <PageHeaderLayout v-show='this.$route.matched.length==2'>
      <div class='main-page-content'>
        <el-row class='table-header'>
          <el-col :span='20'>

            <el-tooltip effect='dark' content='批量添加' placement='top-start'
                        v-if="userPermissions.indexOf('groups_create') !== -1 && buttonType==='icon'">
              <el-button type='primary' icon='el-icon-copy-document' @click='addTransfer(0)'>
                同步自钉钉
              </el-button>
            </el-tooltip>
            <el-button type='primary' icon='el-icon-copy-document'
                       v-if="userPermissions.indexOf('groups_create') !== -1 && buttonType==='text'"
                       @click='addTransfer(0)'>批量添加
            </el-button>

            <el-button type='primary' @click='addButton'><i class='el-icon-plus'></i>添加分组</el-button>

            <el-tooltip effect='dark' content='批量删除' placement='top-start'
                        v-if="userPermissions.indexOf('groups_delete') !== -1 && buttonType==='icon'">
              <el-button type='danger' icon='el-icon-delete' @click='deleteBatch'
                         :disabled='selectionRows.length===0'>批量删除
              </el-button>
            </el-tooltip>
            <el-button type='danger' icon='el-icon-delete'
                       v-if="userPermissions.indexOf('groups_delete') !== -1 && buttonType==='text'"
                       @click='deleteBatch' :disabled='selectionRows.length===0'>批量删除
            </el-button>
          </el-col>
        </el-row>
        <el-form :model='searchCondition' inline>
          <el-form-item label='分组名称'>
            <el-input v-model='searchCondition.display_name' placeholder='分组名称' @change='initData' clearable></el-input>
          </el-form-item>
          <el-form-item>
            <el-button @click='handleSearch' type='primary' icon='el-icon-search'>查询</el-button>
          </el-form-item>
        </el-form>
        <ApeTable ref='apeTable' :data='groupsList' :columns='columns' :loading='loadingStaus' :pagingData='pagingData'
                  @switchPaging='switchPaging' @selectList='handleSelection' @sort-change='changeTableSort'
                  highlight-current-row>
          <el-table-column
            slot='first-column'
            type='selection'
            width='55'>
          </el-table-column>
          <el-table-column
            v-if="buttonType=='icon'"
            label='操作'>
            <template slot-scope='scope'>
              <el-tooltip effect='dark' content='编辑' placement='top-start'
                          v-if="userPermissions.indexOf('groups_edit') != -1">
                <el-button size='mini' icon='el-icon-edit' @click='editButton(scope.row.id)'></el-button>
              </el-tooltip>
              <el-tooltip effect='dark' content='删除' placement='top-start'>
                <span>
                  <el-popover
                    v-if="userPermissions.indexOf('groups_delete') != -1"
                    placement='top'
                    width='150'
                    v-model='scope.row.visible'>
                    <p>确定要删除记录吗？</p>
                    <div style='text-align: right; margin: 0;'>
                      <el-button type='text' size='mini' @click='scope.row.visible=false'>取消</el-button>
                      <el-button type='danger' size='mini' @click='deleteButton(scope.row.id)'>确定</el-button>
                    </div>
                    <el-button slot='reference' type='danger' size='mini' icon='el-icon-delete'></el-button>
                  </el-popover>
                </span>
              </el-tooltip>
            </template>
          </el-table-column>
          <el-table-column
            v-if="buttonType=='text'"
            label='操作'>
            <template slot-scope='scope'>
              <span>
<!--                <el-button size="mini" v-if="userPermissions.indexOf('groups_edit') != -1"-->
                <!--                           @click="editButton(scope.row.id)">编辑</el-button>-->
                <el-popover
                  v-if="userPermissions.indexOf('groups_delete') != -1"
                  placement='top'
                  width='150'
                  v-model='scope.row.visible'>
                  <p>确定要删除记录吗？</p>
                  <div style='text-align: right; margin: 0;'>
                    <el-button type='text' size='mini' @click='scope.row.visible=false'>取消</el-button>
                    <el-button type='danger' size='mini' @click='deleteButton(scope.row.id)'>确定</el-button>
                  </div>
                  <el-button slot='reference' type='danger' size='mini'>删除</el-button>
                </el-popover>
              </span>
            </template>
          </el-table-column>
        </ApeTable>
      </div>
    </PageHeaderLayout>

    <ModalDialog :dialogData='dialogData' @dialogConfirm='handleConfirm' @dialogClose='dialogClose'>
      <template slot='content'>
        <el-form :model='formData' :rules='rules' ref='groupsForm' label-position='right' label-width='110px'>
          <el-form-item label='分组名称' prop='display_name'>
            <el-input v-model='formData.display_name'></el-input>
          </el-form-item>
          <el-form-item label='分组标识' prop='name'>
            <el-input v-model='formData.name'></el-input>
          </el-form-item>
          <el-form-item label='上级分组标识'>
            <el-input v-model='formData.parent_name'></el-input>
          </el-form-item>
          <el-form-item label='备注' prop='description'>
            <el-input type='textarea' v-model='formData.description'></el-input>
          </el-form-item>
        </el-form>
      </template>
    </ModalDialog>

    <ModalDialog :dialogData='dialogGroup' @dialogConfirm='handleGroupConfirm' @dialogClose='dialogGroupClose'>
      <template slot='content'>
        <div>
          <DepartmentsChoice @handleSelectionChange='handleSelectionChange'></DepartmentsChoice>
        </div>
      </template>
    </ModalDialog>

  </div>
</template>

<script>
import PageHeaderLayout from '@/layouts/PageHeaderLayout'
import ApeTable from '@/components/ApeTable'
import ModalDialog from '@/components/ModalDialog'
import ApeDrawer from '@/components/ApeDrawer'
import { mapGetters } from 'vuex'
import TreeTable from '@/components/TreeTable'
import DepartmentsChoice from '@/pages/groups/DepartmentsChoice'

export default {
  name: 'GroupList',
  components: {
    PageHeaderLayout,
    ApeTable,
    TreeTable,
    ModalDialog,
    ApeDrawer,
    DepartmentsChoice
  },
  data() {
    return {
      // 表格列表数据
      treeList: [],
      data: [],
      value: [],
      loadingStaus: false,
      dialogGroup: {
        visible: false,
        title: '分组',
        width: '1000px',
        hide_footer: false,
        loading: true,
        modal: false
      },
      multipleSelection: [],
      selectionRows: [],//选中行
      //添加模态框获取
      dialogData: {
        visible: false,
        title: '',
        width: '600px',
        loading: true,
        modal: false
      },
      columns: [
        {
          title: 'ID',
          value: 'id',
          width: 80,
          sortable: 'custom'
        },
        {
          title: '分组名称',
          value: 'display_name',
          width: 120,
          sortable: 'custom'
        },
        {
          title: '分组标识(来自钉钉)',
          value: 'name',
          width: 140,
          sortable: 'custom'
        },
        {
          title: '上级分组',
          value: 'parent_name',
          width: 100,
          sortable: 'custom'
        },
        {
          title: '备注',
          value: 'description',
          width: 240
        },
        {
          title: '创建时间',
          value: 'created_at'
        },
        {
          title: '修改时间',
          value: 'updated_at'
        }

      ],
      // 表格列表数据
      groupsList: [],
      // 分页信息
      pagingData: {
        is_show: true,
        layout: 'total, sizes, prev, pager, next, jumper',
        page_size: 10,
        total: 0,
        offset: 0
      },
      // 分页的offset,序号列使用
      offset: 0,
      // 表单结构
      formData: {
        display_name: '', parent_name: '', name: '', remark: ''
      },
      // 表单验证
      rules: {
        display_name: [{ required: true, message: '输入分组名称', trigger: 'blur' }],
        name: [{ required: true, message: '输入分组标识', trigger: 'blur' }]
      },
      searchCondition: {},
      orderSort: {}
    }
  },
  computed: {
    ...mapGetters(['userPermissions', 'buttonType'])
  },
  methods: {
    changeTableSort({ prop, order }) {
      let orderSort = { ...this.orderSort }
      if (order) {
        orderSort[prop] = order === 'descending' ? 'desc' : 'asc'
      }
      this.orderSort = orderSort
      this.initData()
    },
    handleSearch() {

      this.initData()
    },
    // 初始化数据
    async initData() {
      let pagingInfo = this.$refs['apeTable'].getPagingInfo()
      let {
        list,
        pages
      } = await this.$api.getMcnGroupList(Object.assign(pagingInfo, this.searchCondition, { order: this.orderSort }))
      this.groupsList = list
      this.pagingData.total = pages.total
      this.pagingData.offset = pages.offset
      this.loadingStaus = false
    },
    // 切换页码操作
    async switchPaging() {
      this.loadingStaus = true
      await this.initData()
      this.loadingStaus = false
    },
    handleSelection(val) {
      this.selectionRows = val
    },
    // 响应添加按钮
    async addButton() {
      this.dialogData.visible = true
      this.dialogData.title = '添加分组'
      this.dialogData.loading = false
    },
    // 响应编辑按钮
    async editButton(id) {
      this.dialogData.visible = true
      this.dialogData.title = '编辑分组'
      let { info } = await this.$api.getMcnGroupInfo(id)
      this.formData = info
      this.dialogData.loading = false
    },
    // form数据提交，请求接口
    async formSubmit() {
      let id = await this.$api.saveMcnGroup(this.formData)
      if (id) {
        this.initData()
      }
      this.initFormData()
      this.$message.success('保存成功!')
    },
    // 相应删除按钮
    async deleteButton(id) {
      let info = await this.$api.deleteMcnGroup({ id: id })
      if (info == 'ok') {
        this.initData()
        this.$message.success('删除成功!')
      } else {
        this.$message.error(info)
      }
    },
    deleteBatch() {
      let ids = []
      this.selectionRows.forEach((row) => {
        let id = row.id
        ids.push(id)
      })

      this.$confirm('此操作将永久删除选中的[' + ids.length + ']个分组, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.deleteRows(ids)
      }).catch(() => {
        this.$message({
          type: 'info',
          message: '已取消删除'
        })
      })
    },
    async deleteRows(ids) {
      if (ids.length > 0) {
        await this.$api.deleteMcnGroup({ ids: ids })
        await this.initData()
        this.$message({
          type: 'success',
          message: '删除成功!'
        })
      }
    },
    // 处理模态框，确定事件
    handleConfirm() {
      // 调用组件的数据验证方法
      this.$refs['groupsForm'].validate((valid) => {
        if (valid) {
          this.formSubmit()
        } else {
          this.$message.error('数据验证失败，请检查必填项数据！')
        }
      })
    },
    // 处理模态框，关闭事件
    dialogClose() {
      this.initFormData()
    },
    // 初始化数据
    async initFormData() {
      // 初始化form表单
      this.$nextTick(() => {
        this.dialogData.visible = false
        this.dialogData.loading = true
        this.formData = {
          name: '',
          display_name: ''
        }
      })
      this.$refs['groupsForm'].resetFields()
      this.initData()
    },
    dialogGroupClose() {
      this.dialogGroup.visible = false
      this.dialogGroup.loading = true
      this.initData()
    },
    async handleGroupConfirm() {
      this.dialogGroup.visible = false
      this.dialogGroup.loading = true
      // console.log('groups:', this.multipleSelection)
      let info = await this.$api.saveDepthMcnGroup({ groups: this.multipleSelection })
      if (info == 'ok') {
        this.$notify.success('保存成功！')
        this.initData()
      }

    },
    // 响应添加按钮
    async addTransfer() {
      this.dialogGroup.visible = true
      this.dialogGroup.title = '从钉钉部门中获取红人名录MCN分组'
      this.dialogGroup.loading = false
      // this.$nextTick(() => {
      //   if (this.data.length === 0)
      //     this.getDepartmentsData()
      // })
    },
    async getDepartmentsData() {
      let { dpts, groups } = await this.$api.getDepartmentsData()
      this.data = dpts
      this.value = groups
    },
    transferHandleChange(value, direction, movedKeys) {
      // console.log('data', this.data)
      // console.log('value', this.value)
      switch (direction) {
        case 'right':
          //添加MCN分组
          this.$api.saveMcnGroupByDptIds({ dpt_ids: movedKeys })
          break
        case 'left':
          //删除MCN分组
          this.$api.deleteMcnGroupByDptIds({ dpt_ids: movedKeys })
          break
        default:
          console.log(value, direction, movedKeys)
          break
      }
    },
    handleSelectionChange(val) {
      this.multipleSelection = val
    }
  },
  async mounted() {
    //
    this.initData()
  }
}
</script>

<style lang='stylus'>
.el-button
  margin-right 4px
  margin-bottom 4px

.table-header
  margin-bottom 12px

.drag-handle
  font-size 24px
  cursor pointer

.el-input-group__prepend, .el-input-group__append
  background #ffffff
  padding 0 12px

.el-input-group__append
  color #ffffff
  background #1890ff

.permissions-group-name
  padding-left 10px
  cursor pointer

.permissions-checked
  width 144px
  display inline-block;
  height 40px;
  line-height 40px;
  padding 0 12px 0 12px
  box-sizing border-box
  margin 8px 12px 0 0
  border 1px solid #e8e8e8
  border-radius 5px
  font-size 14px
  overflow hidden

  .el-checkbox
    margin-right 0

.el-popover .el-checkbox-group .el-checkbox
  margin-left 0
  margin-right 12px
</style>
